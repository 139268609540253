import {AuditEntityInterface, HistoryEntityInterface} from '@/interfaces/entity_types'
import {ContractInterimReassignmentInterface} from '@/interfaces/contract_types'
import {LeadInterimReassignmentInterface} from '@/interfaces/lead_types'

export const AG_VITA_APPLICATION_USER_ID = 2

export interface UserOption {
    id: string
    description: string
}

export interface GenevaUserInterface extends HistoryEntityInterface {
    [key: string]: any

    id: number
    provider_id: number
    works_for_provider_id: number
    is_active: boolean

    email: string
    phone: string
    preferred_first_name: string
    first_name: string
    last_name: string
    compact: boolean
    responsive_font_sizes: boolean
    rounded_corners: boolean
    theme: string

    provider_name: string
    full_name: string
    description: string
    works_for_supplier_id: number
    works_for_portal_supplier_id: number

    avatar_data_file_id: number
    avatar_data_file_url: string
    avatar_data_file_container_name: string

    emergency_contact_first_name: string
    emergency_contact_last_name: string
    emergency_contact_mobile: string
    emergency_contact_phone: string
    emergency_contact_email: string
    lu_emergency_contact_person_relationship_type_id: number

    emergency_contact_person_relationship_type_description: string
    emergency_contact_full_name: number

    application_user_roles: GenevaUserRoleInterface[]
    active_application_user_roles: GenevaUserRoleInterface[]
    possible_application_user_roles: GenevaUserRoleInterface[]

    reports_to_application_user_id: number
    reports_to_application_user_full_name: string

    is_manager: boolean

    is_provider_admin: boolean
    is_manager_sales: boolean
    is_head_of_operations: boolean
    is_manager_finance: boolean
    is_head_of_quality_and_practice: boolean
    is_team_leader: boolean
    is_concierge: boolean
    is_clinical_manager: boolean
    is_care_manager: boolean
    is_senior_care_manager: boolean
    is_service_coordinator: boolean
    is_operational_admin: boolean
    is_home_care_specialist: boolean
    is_bank_details_manager: boolean
    is_corporate_services: boolean
    is_head_of_commercial: boolean
    is_finance_admin: boolean
    is_user_manager: boolean
    is_human_resources_manager: boolean
    is_incident_manager: boolean
    is_operations_manager_care_management: boolean
    is_sales_team_leader: boolean
    is_service_coordinator_team_leader: boolean
    is_concierge_team_leader: boolean
    is_clinical_quality_manager: boolean
    is_operations_manager_client_services: boolean
    is_business_development_manager: boolean
    is_ceo: boolean
    is_home_care_specialist_field: boolean

    can_edit_bank_details: boolean
    can_create_bank_details: boolean
    can_view_bank_details: boolean
    can_create_supplier: boolean

    is_admin: boolean
    is_sales: boolean
    is_operations: boolean
    is_finance: boolean
    is_compliance: boolean

    person_id: number
    has_subordinates: boolean
    subordinate_application_user_ids: number[]

    auth0_user_id: number
    last_login_timestamp: Date
}

export interface GenevaUserRoleInterface {
    created_on: Date
    created_by_application_user_id: number
    updated_on: Date
    change_description: string
    application_user_id: number
    application_user_application_user_id: number
    application_user_application_user_full_name: string

    is_active: boolean

    lu_application_user_role_id: number
    application_user_role_description: string

    description: string

    is_manager: boolean
    is_provider_admin: boolean
    is_manager_sales: boolean
    is_head_of_operations: boolean
    is_manager_finance: boolean
    is_head_of_quality_and_practice: boolean
    is_team_leader: boolean
    is_concierge: boolean
    is_clinical_manager: boolean
    is_care_manager: boolean
    is_service_coordinator: boolean
    is_operational_admin: boolean
    is_home_care_specialist: boolean
    is_bank_details_manager: boolean
    is_corporate_services: boolean
    is_finance_admin: boolean
    is_human_resources_manager: boolean
    is_operations_manager_care_management: boolean
    is_sales_team_leader: boolean
    is_service_coordinator_team_leader: boolean
    is_concierge_team_leader: boolean
    is_clinical_quality_manager: boolean
    is_operations_manager_client_services: boolean
    is_business_development_manager: boolean
    is_ceo: boolean
    is_home_care_specialist_field: boolean

    is_admin: boolean
    is_sales: boolean
    is_operations: boolean
    is_finance: boolean
    is_compliance: boolean

    is_provider_worker: boolean
    is_supplier_service_worker: boolean
    is_supplier_portal_worker: boolean
}

export const enum ApplicationUserRoleEnum {
    LU_USER_ROLE_PROVIDER_ADMIN = 1,
    LU_USER_ROLE_MANAGER_SALES = 2,
    LU_USER_ROLE_HEAD_OF_OPERATIONS = 3,
    LU_USER_ROLE_MANAGER_FINANCE = 4,
    LU_USER_ROLE_HEAD_OF_QUALITY_AND_PRACTISE = 5,
    LU_USER_ROLE_CLINICAL_MANAGER = 6,
    LU_USER_ROLE_TEAM_LEADER = 7,
    LU_USER_ROLE_CONCIERGE = 8,
    LU_USER_ROLE_CARE_MANAGER = 9,
    LU_USER_ROLE_SERVICE_COORDINATOR = 10,
    LU_USER_ROLE_OPERATIONAL_ADMIN = 11,
    LU_USER_ROLE_HOME_CARE_SPECIALIST = 12,
    LU_USER_ROLE_BANK_DETAILS_MANAGER = 13,
    LU_USER_ROLE_CORPORATE_SERVICES = 14,
    LU_USER_ROLE_FINANCE_ADMIN = 15,
    LU_USER_ROLE_HUMAN_RESOURCES_MANAGER = 16,
    LU_USER_ROLE_HEAD_OF_COMMERCIAL = 17,
    LU_USER_ROLE_OPERATIONS_MANAGER_CARE_MANAGEMENT = 18,
    LU_USER_ROLE_SALES_TEAM_LEADER = 19,
    LU_USER_ROLE_SERVICE_COORDINATOR_TEAM_LEADER = 20,
    LU_USER_ROLE_CONCIERGE_TEAM_LEADER = 21,
    LU_USER_ROLE_SENIOR_CARE_MANAGER = 22,
    LU_USER_ROLE_CLINICAL_QUALITY_MANAGER = 23,
    LU_USER_ROLE_OPERATIONS_MANAGER_CLIENT_SERVICES = 24,
    LU_USER_ROLE_BUSINESS_DEVELOPMENT_MANAGER = 25,
    LU_USER_ROLE_CEO = 26,
    LU_USER_ROLE_HOME_CARE_SPECIALIST_FIELD = 27,
}

export interface ApplicationUserSearchResult extends AuditEntityInterface {
    id: number
    user_id: number
    person_id: number
    supplier_id: number
    lead_id: number
    application_user_id: number
    description: string
    is_person: boolean
    is_client: boolean
    is_associated_person: boolean
    is_worker: boolean
    is_provider_worker: boolean
    is_supplier: boolean
    is_lead: boolean
    is_open: boolean
}

export const enum AgendaLeadTypeEnum {
    LU_AGENDA_LEAD_TYPE_IN_PROGRESS = 1,
    LU_AGENDA_LEAD_TYPE_APPROVED = 2,
    LU_AGENDA_LEAD_TYPE_ASSIGNED = 3,
    LU_AGENDA_LEAD_TYPE_OTHER = 4,
}

export const AgendaLeadTypeMap: Map<number, string> = new Map([
    [AgendaLeadTypeEnum.LU_AGENDA_LEAD_TYPE_OTHER, 'Other'],
    [AgendaLeadTypeEnum.LU_AGENDA_LEAD_TYPE_APPROVED, 'Approved'],
    [AgendaLeadTypeEnum.LU_AGENDA_LEAD_TYPE_ASSIGNED, 'Assigned'],
])

export interface ApplicationAgendaItemInterface {
    agenda_date: Date
    assigned_to_application_user_id: number
    assigned_to_application_user_description: string
    is_task: boolean
    is_client_task: boolean
    is_call_back_task: boolean
    is_meeting_appointment: boolean
    is_person: boolean
    is_birthday: boolean
    is_overdue_activation: boolean
    is_care_plan: boolean
    is_sla: boolean
    is_supplier_lead: boolean
    is_confirmed_with_client: boolean
    is_unregistered_contract_leave: boolean
    is_allocation_lead: boolean
    is_no_next_task_lead: boolean
    is_package_expiring_lead: boolean
    is_interim_reassignment: boolean
    client_description: string
    contact_description: string
    details: string
    description: string
    due_date: Date | null
    milan_path: string
    lu_agenda_lead_type_id: number
    key: string
    task_id: number
    lead_id: number
    is_allocated_lead_in_market: boolean
    lu_task_priority_id: number
}

export interface ApplicationAgendaInterface {
    task_limit: number
    agenda_items: ApplicationAgendaItemInterface[]
    count_tomorrow_agenda_items: number
    count_tomorrow_call_back_agenda_items: number
}

export interface OverdueCarePlanActivationsClient {
    id: number
    client_name: string
    icma_date: Date
    provider_accepted_date: Date
    care_manager_application_user_description: string
    care_plan_due: Date
    days_overdue: number
}

export interface OverdueCarePlanActivationsInterface {
    overdue_care_plan_activation_clients: OverdueCarePlanActivationsClient[]
}

export interface ApplicationUserLeaveInterface extends HistoryEntityInterface {
    id: number
    user_id: number
    from_date: Date
    to_date: Date
    is_hide_incomplete: boolean

    contract_interim_reassignments: ContractInterimReassignmentInterface[]
    lead_interim_reassignments: LeadInterimReassignmentInterface[]
}
